.container-fluid.content {
	height: calc(100vh - 70px);
	display: flex;
	align-items: center;
	margin-top: 70px;
}

.welcome-support-type {
	padding: 10px;
	margin-bottom: calc(var(--default-margin) / 2);
}

.welcome-support-type a {
	display: inline-flex;
	padding: 30px 40px;
	border: 1px solid transparent;
	border-radius: 10px;
	transition: all .2s ease;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	box-shadow: 0 .3rem .3rem rgba(0, 0, 0, .15);
	min-width: 190px;
}

.welcome-support-type a:hover {
	border: 1px solid var(--primary-color);
	transform: scale(1.03);
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.welcome-support-type a i {
	margin: 0 auto;
	color: var(--primary-color);
	font-size: 100px;
}
