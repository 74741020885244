.container-fluid.kb-article-details-container {
	display: flex;
	align-items: center;
	margin-top: 70px;
	padding-top: 40px;
}

.kb-article-details-container .kb-article-status {
	position: absolute;
	top: 0px;
	right: 15px;
	padding: 10px 20px;
	color: #fff;
	font-size: 18px;
	/* border: 1px solid #2c2c2c; */
	border-radius: .25rem;
}

.kb-article-reply.expanded .kb-article-reply-title {
	background-color: #eee;
	border-bottom: 1px solid #ddd;
}

.kb-article-reply-button {
	position: absolute;
	top: 5px;
	right: 10px;
	font-size: 20px;
	cursor: pointer;
}
