:root {
	--default-background-color: #fff;
	--container-background-color: #fff;
	--subcontainer-background-color: #eee;
	--default-margin: 30px;
	--default-radius: 4px;
	--primary-color: #007bff;
	--primary-font: 'Poppins', sans-serif;
	--text-color: #3c4257;
	--default-border-color: #f1f1f1;
	--default-border-radius: 4px;
	--default-hover-background: #f2f2f2;
	--cart-icon-background-color: #f44336;
	--cart-icon-text-color: white;
	--modal-dialog-background-color: white;
	--modal-buttons-shaded-color: #f5f6fa;
	--modal-buttons-shaded-border-color: #ddd;
	--panel-background-color: #f5fafe;
	--success-background-color: #d5fdcc;
	--success-border-color: #93fb7c;
	--success-text-color: #62c73b;
	--link-color: #1a0dab;
}

* {
	margin: 0;
}

.rw-table {
	/* margin: 30px 0 30px 0; */
}

.rw-table-header {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 1px solid #cccccc;
}

.rw-table-header .rw-th {
	padding: 10px;
	text-align: left;
	font-weight: bold;
}

.rw-table-header .rw-th:first-child {
	padding-left: 20px;
}

.rw-table-header .rw-th:last-child {
	padding-right: 20px;
}

.rw-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 0;
}

.rw-row .rw-td {
	padding: 10px 10px;
	text-align: left;
	font-weight: normal;
	font-size: 14px;
}

.rw-row .rw-td:first-child {
	padding-left: 20px;
}

.rw-row .rw-td:last-child {
	padding-right: 20px;
}

.rw-table .action {
	text-align: right;
}

.rw-table .action .btn {
	min-height: initial;
	font-size: 14px;
}

.rw-table .action .btn i {
	margin-right: 10px;
}

.btn i {
	margin-right: 10px;
}

.btn.btn-primary {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
	filter: brightness(120%);
	box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
	filter: brightness(120%);
}

.btn-primary.focus,
.btn-primary:focus {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
	filter: brightness(120%);
	box-shadow: none;
}

.btn.btn-primary:hover {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
	filter: brightness(120%);
}

.btn.btn-outline-primary {
	border-color: var(--default-background-color);
	color: var(--default-background-color);
}

.btn.btn-outline-primary:hover {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
	box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
	box-shadow: none;
}

.btn-link {
	color: var(--primary-color);
}

.btn-link:hover {
	color: var(--primary-color);
	filter: brightness(120%);
}

.btn-input-search {
	position: absolute;
	margin-left: -41px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	-webkit-appearance: none;
}
