.app-sidebar-modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .6);
	z-index: 98;
}

.app-sidebar-modal.active {
	display: block;
}

.app-sidebar {
	position: fixed;
	display: flex;
	top: 0;
	right: 0;
	visibility: hidden;
	flex-direction: column;
	height: 100vh;
	width: 33.3333333vw;
	background-color: white;
	box-shadow: 0 15px 35px 0 rgba(60, 66, 87, .08), -5px -5px 35px 0 rgba(0, 0, 0, .12);
	transition: all .3s ease-out;
	transform: translateX(100%);
	z-index: 99;
}

.app-sidebar.active {
	visibility: visible;
	transform: translateX(0%);
}

.app-sidebar .app-sidebar-actions {
	padding: calc(var(--default-margin) / 2) var(--default-margin);
	text-align: right;
}

.app-sidebar .app-sidebar-actions .app-link {
	font-size: 24px;
}

.app-sidebar hr {
	margin: 0;
}

.app-sidebar .app-sidebar-content {
	padding: var(--default-margin);
	flex: 1;
	overflow-y: auto;
}

@media screen and (max-width: 767px) {
	.app-sidebar {
		width: 90vw;
	}
}
