.table-tickets-list.rw-table .last-reply {
	width: 15%;
}

.table-tickets-list.rw-table .number {
	width: 20%;
}

.table-tickets-list.rw-table .subject {
	width: 40%;
}

.table-tickets-list.rw-table .status {
	width: 10%;
}

.table-tickets-list.rw-table .action {
	width: 15%;
}