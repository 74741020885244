#calendar {
	position: relative;
	/* padding-bottom: 110%; */
	height: 100%;
}

#calendar iframe {
	position: absolute;
	width: 100%;
	height: 100%;
}
