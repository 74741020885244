.main-app {
	min-height: 100vh;
}

.main-app .main-app-container {
	width: 100%;
	min-height: 100vh;
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.actions-bar-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	text-align: left;
	padding: 0 var(--default-margin);
	padding-bottom: var(--default-margin);
}

.actions-bar-container .panel-actions {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.actions-bar-container .panel-actions .btn {
	margin-right: calc(var(--default-margin) / 2);
}

.actions-bar-container .panel-actions .btn:last-child {
	margin-right: 0;
}

@media screen and (max-width: 767px) {
	.actions-bar-container {
		display: block;
	}
}
