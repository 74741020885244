.header {
	position: fixed;
	background-color: #fff;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
	border-bottom: 1px solid #ccc;
	z-index: 2;
}

.header img {
	max-height: 30px;
}

.header-nav {
	list-style: none;
	display: inline-flex;
	margin-bottom: 0;
}

.header-nav li a:hover {
	color: var(--primary-color);
}
