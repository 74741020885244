.table-kb-articles-list.rw-table .title {
	width: 70%;
}

.table-kb-articles-list.rw-table .date {
	width: 15%;
}

.table-kb-articles-list.rw-table .action {
	width: 15%;
}

.table-kb-articles-list.rw-table .rw-row:hover {
	background-color: #eeeeee;
}
