.container-fluid.ticket-details-container {
	display: flex;
	align-items: center;
	margin-top: 70px;
	padding-top: 40px;
}

.ticket-reply {
	position: relative;
	border: 1px solid #2c2c2c;
	max-height: 42px;
	overflow: hidden;
	-webkit-transition: max-height .3s ease-out;
	-moz-transition: max-height .3s ease-out;
	-o-transition: max-height .3s ease-out;
	transition: max-height .3s ease-out;
}

.ticket-reply.expanded {
	position: relative;
	border: 1px solid #2c2c2c;
	height: auto;
	max-height: 300px;
	overflow: auto;
	-webkit-transition: max-height .3s ease-out;
	-moz-transition: max-height .3s ease-out;
	-o-transition: max-height .3s ease-out;
	transition: max-height .3s ease-out;
}

.ticket-details-container .ticket-status {
	position: absolute;
	top: 0px;
	right: 15px;
	padding: 10px 20px;
	color: #fff;
	font-size: 18px;
	/* border: 1px solid #2c2c2c; */
	border-radius: .25rem;
}

.ticket-details-container .ticket-status.active {
	background-color: #15b175;
}

.ticket-details-container .ticket-status.closed {
	background-color: #cf2828;
}

.ticket-reply.expanded .ticket-reply-title {
	background-color: #eee;
	border-bottom: 1px solid #ddd;
}

.ticket-reply-button {
	position: absolute;
	top: 5px;
	right: 10px;
	font-size: 20px;
	cursor: pointer;
}
